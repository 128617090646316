import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import FullPageLayout from "../components/full-page-layout"
import EmailVerifyForm from "../components/auth/email-verify-form"
import { useUser } from "../context/user-context"

function EmailVerifyPage() {
  const { allPrismicLoginPage } = useStaticQuery(graphql`
    query EmailVerifyPageQuery {
      allPrismicLoginPage {
        edges {
          node {
            data {
              right_image {
                alt
                url
              }
              email_verification_title {
                html
                text
              }
              email_verification_body_text {
                html
                text
              }
            }
          }
        }
      }
    }
  `)
  const {
    right_image,
    email_verification_title,
    email_verification_body_text,
  } = allPrismicLoginPage.edges[0].node.data

  const user = useUser()

  const emailVerificationText = () => {
    return email_verification_body_text?.text.replace(
      "{{user.email}}",
      user?.email
    )
  }

  return (
    <FullPageLayout rightImageUrl={right_image.url}>
      <div className="flex items-center pt-32 lg:pt-36">
        <div className="text-center w-full px-6 lg:px-16">
          <div className="w-full flex items-center">
            <div className="w-full">
              <h1 className="headline-text">
                {email_verification_title?.text}
              </h1>
              <p className="body-text mt-1 mb-0 and pb-0">
                {emailVerificationText()}
              </p>
            </div>
          </div>
          <div className="mx-auto md:w-2/3">
            <EmailVerifyForm />
          </div>
        </div>
      </div>
    </FullPageLayout>
  )
}

export default EmailVerifyPage
