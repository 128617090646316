import React from "react"
import PropTypes from "prop-types"

function FullPageLayout({ children, rightImageUrl }) {
  return (
    <div className="max-w-fuller mx-auto md:flex lg:p-0">
      <div className="w-full md:w-1/2">
        <div className="w-full pt-8 pl-6 md:pl-12">
          <a className="max-w-xs" href="/">
            <img
              src="https://mvp-lantern-production.s3.us-east-2.amazonaws.com/LOGO_Black.png"
              width={150}
              alt="Lantern"
            />
          </a>
        </div>
        {children}
      </div>
      <div
        className="hidden md:block w-full md:w-1/2 bg-primary registration-bg-image"
        style={{ backgroundImage: `url(${rightImageUrl})` }}
      ></div>
    </div>
  )
}

FullPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  rightImageUrl: PropTypes.string.isRequired,
}

export default FullPageLayout
