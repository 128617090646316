import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useAlert } from "react-alert"
import { useAuth } from "../../context/auth-context"
import { useUser } from "../../context/user-context"
import { isBrowser } from "../../utils/browser"
import Button from "../button"

function EmailVerifyForm() {
  const user = useUser()
  const alert = useAlert()
  const { ResendVerificationEmail, VerifyEmail, logout } = useAuth()
  const [isResendLoading, setIsResendLoading] = useState(false)
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)
  const [error, setError] = useState(null)
  const [code, setCode] = useState("")

  useEffect(() => {
    if (!user) {
      navigate("/login")
    }
    if (
      (user && user?.is_email_verified) ||
      !user?.requires_email_verification
    ) {
      navigate("/app/dashboard")
    }
  }, [])

  const handleChange = ({ target }) => {
    setCode(target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setIsVerifyLoading(true)

    VerifyEmail({ variables: { code } })
      .then((res) => {
        if (res?.data?.verifyEmail?.user) {
          navigate("/app/dashboard")
        } else {
          setIsVerifyLoading(false)
        }
      })
      .catch((error) => {
        setError(error)
        setIsVerifyLoading(false)
      })
  }

  const handleResend = () => {
    setIsResendLoading(true)

    ResendVerificationEmail()
      .then((res) => {
        if (res?.data?.resendVerificationEmail?.status === 201) {
          if (isBrowser()) {
            alert.info(`We've resent the email to ${user?.email}`)
          }
        }
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setIsResendLoading(false)
      })
  }

  const logoutUser = () => {
    logout().then(() => navigate("/"))
  }

  return (
    <form onSubmit={handleSubmit} className="lg:px-8 pt-6 lg:pb-8">
      <div className="w-full">
        <label className="block md:w-1/2 pr-3 mt-4 mb-2"></label>
        <input
          onChange={handleChange}
          className="default-form-field w-full"
          id="code"
          name="code"
          type="text"
          placeholder="Code"
          required
          aria-label="Code"
        />
        {error && <small className="text-error">{error.message}</small>}
      </div>
      <div className="py-4 mx-auto">
        <Button
          buttonText={isVerifyLoading ? "Verifying email..." : "Submit"}
          buttonType="btn in-app -primary"
          disabled={isVerifyLoading}
          eventName={{
            eventDomain: "App",
            eventLocation: "Verify Email Form",
            eventAction: "Click",
            eventActionName: "Submit Code",
          }}
        />
        <div
          onClick={handleResend}
          className="body-text py-3 block cursor-pointer"
        >
          {isResendLoading ? "Resending code..." : "Resend Code"}
        </div>
        <div
          onClick={logoutUser}
          className="body-text py-3 block cursor-pointer"
        >
          Logout
        </div>
      </div>
    </form>
  )
}

EmailVerifyForm.propTypes = {}

export default EmailVerifyForm
